import { useMemo, useState } from 'react';
import fetchShares from '../helpers';
const useGetShareCount = ({ campaignId }) => {
    const [shareCount, setShareCount] = useState(0);
    const countTotalEngagements = (engagement) => {
        var _a, _b, _c;
        let engagementCount = 0;
        engagementCount += (_a = engagement.reaction_count) !== null && _a !== void 0 ? _a : 0;
        engagementCount += (_b = engagement.comment_count) !== null && _b !== void 0 ? _b : 0;
        engagementCount += (_c = engagement.share_count) !== null && _c !== void 0 ? _c : 0;
        return engagementCount;
    };
    useMemo(() => fetchShares(campaignId).then((value) => {
        if (value === null || value === void 0 ? void 0 : value.engagement) {
            setShareCount(countTotalEngagements(value === null || value === void 0 ? void 0 : value.engagement));
        }
    }, (reason) => {
        console.error(reason);
    }), [campaignId]);
    return shareCount;
};
export default useGetShareCount;
