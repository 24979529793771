import React from "react";
import LinkButton from "components/LinkButton";
import ProgressMeter from "components/ProgressMeter/ProgressMeter";
import { campaignEndTimeDisplay } from "helpers/campaignEndTimeDisplay";
import { PropTypes } from "prop-types";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import SellingPointsWell from "../SellingPointsWell";

const CampaignSideBar = ({
  viewport,
  campaignEndsTime,
  campaign,
  entity,
  ownerName,
  paymentUrl,
  shouldShowMatchedGiving,
}) => {
  const buttonSize = viewport === "mobile" ? "desktop" : "largev2";
  const { translate: t } = useLanguageProvider();
  if (!campaign) {
    return null;
  }

  const campaignIsActive = campaign.status === "Live" || campaign.status === "Approved" || campaign.status === "Infinity";

  return (
    <>
      <div className="well">
        <div className="campaign-container__animated-total">
          <div className="campaign-container__progress-bar">
            <ProgressMeter
              currency={campaign.target.currency}
              currencySymbol={campaign.target.currencyNode.symbol}
              raised={entity.raised || 0}
              target={+entity.target}
            />
          </div>
          <LinkButton
            href={paymentUrl}
            look="primary"
            isBold
            isDisabled={!campaignIsActive}
            size={buttonSize}
            data-test={`campaign-donate-button-${viewport}`}
            className="campaign-container__donate-button"
          >
            {campaignIsActive
              ? shouldShowMatchedGiving
                ? t("Double your donation")
                : t("Donate now")
              : t("Donations closed")}
          </LinkButton>

          {campaignEndTimeDisplay(
            campaign.duration.isInfinityMode,
            campaign.status,
            campaignEndsTime,
          ) && (
            <p className="campaign-container__countdown --notification">
              { t("Ends in") }
              :
              {" "}
              <b>{campaignEndsTime}</b>
            </p>
          )}
        </div>
      </div>
      <div className="well">
        <SellingPointsWell ownerName={ownerName} />
      </div>
    </>
  );
};

export default CampaignSideBar;

CampaignSideBar.propTypes = {
  viewport: PropTypes.string,
  campaignEndsTime: PropTypes.string,
  campaign: PropTypes.object,
  entity: PropTypes.object.isRequired,
  ownerName: PropTypes.string,
  paymentUrl: PropTypes.string.isRequired,
  shouldShowMatchedGiving: PropTypes.bool,
};
